<template>
    <v-sheet v-bind="$attrs">
        <slot v-bind="{ products }" />
    </v-sheet>
</template>

<script>
import api from "@/api";

export default {
    props: {
        code: { type: String, default: null },

        sort: { type: String, default: undefined },
        skip: { type: [String, Number], default: undefined },
        limit: { type: [String, Number], default: undefined },
    },
    data: () => ({
        displays: [],
        loading: false,
    }),
    computed: {
        products() {
            return this.displays.map(({ product } = {}) => product).filter((item) => !!item);
        },
        headers() {
            const { skip, limit, sort } = this;

            const headers = { sort, skip, limit };

            if (sort == undefined) delete headers.sort;
            if (skip == undefined) delete headers.skip;
            if (limit == undefined) delete headers.limit;

            return { ...headers };
        },
        params() {
            const { code } = this;
            return { code };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.init();
        },
        loading(loading) {
            this.$emit("setLoading", loading);
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { headers, params } = this;
                const { displays } = await api.v1.shop.displays.gets({
                    headers,
                    params,
                });
                this.displays = displays;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
